import React, { Component } from "react";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import { Helmet } from "react-helmet";
import classnames from "classnames";
import cssClass from "./section-home.module.css";
import curtainMobile from "../assets/curtain-mobile.mp4"
import curtainHighRes from "../assets/curtain-highres.mp4"
import curtainLowRes from "../assets/curtain-lowres.mp4"


class SectionHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false
    }

    this.video = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { documentScrollPosition, height } = this.props;
    if (documentScrollPosition !== prevProps.documentScrollPosition) {
      if (documentScrollPosition > height) {
        this.video.current.pause();
      } else {
        this.video.current.play();
      }
    }
    if (height !== prevProps.height) {
      this.setState({ loaded: true });
    }
  }

  render() {
    const { data, documentScrollPosition, height, width } = this.props;
    const { loaded } = this.state;
    
    const percentageScrolled = documentScrollPosition / height;
    const highRes = width >= 768;
    const mobile = (width < 450 && height < 813) || (height < 450 && width < 813);

    const logoStyles = classnames(cssClass.logo, {
      [cssClass.show]: loaded
    });

    const logo = mobile ? (
      <Img fluid={data.awcLogo.childImageSharp.fluid} alt="Assemble with Care" className={logoStyles} />
    ) : (
      <img src={data.awcLogoGif.publicURL} alt="Assemble with Care" className={logoStyles} />
    );

    let styles;
    let videoSrc;
    let firstFrame;
    if (height) {
      styles = {
        transform: `translateY(-${(percentageScrolled * 100) * 1.5}px) translateZ(0)`,
        opacity: 1 - percentageScrolled * 1.5
      }
      if (mobile) {
        videoSrc = curtainMobile;
        firstFrame = "/static/b2b7a8dcb9f5e637edf1550734b01584/74219/curtain-firstframe-mobile.png";
      } else if (highRes) {
        videoSrc = curtainHighRes;
        firstFrame = "/static/2492eb05632e417fb3e53b84c777e951/ba299/curtain-firstframe.png?w=1200";
      } else {
        videoSrc = curtainLowRes;
        firstFrame = "/static/2492eb05632e417fb3e53b84c777e951/ba299/curtain-firstframe.png?w=1200";
      }
    }

    return (
      <div className={cssClass.home}>
        <Helmet>
          <link rel="preconnect" href="/static/2492eb05632e417fb3e53b84c777e951/ba299/curtain-firstframe.png?w=1200" />
        </Helmet>
        <div className={cssClass.content}>
          <img src={firstFrame} alt="" className={cssClass.firstframe} />
          <video
            src={videoSrc}
            className={cssClass.videoBackground}
            autoPlay
            loop
            playsInline
            muted
            ref={this.video}
            type="video/mp4"
          >
            <track kind="captions" />
          </video>
          <h1 className={cssClass.logoWrapper} style={styles}>
            {logo}
          </h1>
        </div>
      </div>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        awcLogoVideo: file(relativePath: { eq: "awc-logo.webm" }) {
          publicURL
        }
        awcLogoGif: file(relativePath: { eq: "awc-logo.gif" }) {
          publicURL
        }
        awcLogo: file(relativePath: { eq: "assemble-with-care-logo.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        firstframe: file(relativePath: { eq: "curtain-firstframe.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        firstframeMobile: file(relativePath: { eq: "curtain-firstframe-mobile.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => <SectionHome data={data} {...props} />}
  />
);