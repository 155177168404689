import SectionHome from "../components/section-home";
import SectionVideo from "../components/section-video";
import SectionAbout from "../components/section-about";
import SectionFeatures from "../components/section-features";
// import SectionReviews from "../components/section-reviews";
import SectionGallery from "../components/section-gallery";

const sections = [
  { name: "home", component: SectionHome },
  { name: "video", component: SectionVideo },
  { name: "about", component: SectionAbout },
  { name: "features", component: SectionFeatures },
  // { name: "reviews", component: SectionReviews },
  { name: "gallery", component: SectionGallery }
];

export default sections;