import React, { Component } from "react";
import { graphql, StaticQuery } from "gatsby";
import { Carousel } from "react-materialize";
import cssClass from "./section-gallery.module.css";

class SectionGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      shouldRender: true
    }

    this.didResize = this.didResize.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loaded: true
      });
    }, 2000);

    this.didResize();
    window.addEventListener("resize", this.didResize, false);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.didResize, false);
  }

  didResize() {
    const { shouldRender } = this.state;

    if (shouldRender) {
      this.setState({
        shouldRender: false
      });
      setTimeout(() => {
        this.setState({
          shouldRender: true
        });
      }, 100);
    }
  }
  
  render() {
    const { data, width } = this.props;
    const { loaded, shouldRender } = this.state;
    const imageSize = width < 600 ? `?w=300` : ``;

    const images = [
      `${data.image1.childImageSharp.fixed.src}${imageSize}`, 
      `${data.image2.childImageSharp.fixed.src}${imageSize}`,
      `${data.image3.childImageSharp.fixed.src}${imageSize}`,
      `${data.image4.childImageSharp.fixed.src}${imageSize}`,
      `${data.image5.childImageSharp.fixed.src}${imageSize}`,
      `${data.image6.childImageSharp.fixed.src}${imageSize}`,
      `${data.image7.childImageSharp.fixed.src}${imageSize}`
    ];

    const options = {
      indicators: true,
      dist: -50,
      shift: -100
    }

    return (
      <div className={cssClass.gallery}>
        {(loaded && shouldRender) && <Carousel images={images} className={cssClass.carousel} options={options} />}
      </div>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        image1: file(relativePath: { eq: "screenshot-camera.jpg" }) {
          childImageSharp {
            fixed {
              src
            }
          }
        }
        image2: file(relativePath: { eq: "screenshot-cassette.jpg" }) {
          childImageSharp {
            fixed {
              src
            }
          }
        }
        image3: file(relativePath: { eq: "screenshot-projector.jpg" }) {
          childImageSharp {
            fixed {
              src
            }
          }
        }
        image4: file(relativePath: { eq: "screenshot-record.jpg" }) {
          childImageSharp {
            fixed {
              src
            }
          }
        }
        image5: file(relativePath: { eq: "screenshot-watch.jpg" }) {
          childImageSharp {
            fixed {
              src
            }
          }
        }
        image6: file(relativePath: { eq: "screenshot-story-suitcase.jpg" }) {
          childImageSharp {
            fixed {
              src
            }
          }
        }
        image7: file(relativePath: { eq: "screenshot-story-helena.jpg" }) {
          childImageSharp {
            fixed {
              src
            }
          }
        }
      }
    `}
    render={data => <SectionGallery data={data} {...props} />}
  />
);
