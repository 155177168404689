import React, { Component } from "react";
import { connect } from "react-redux";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import classnames from "classnames";
import cssClass from "./section-video.module.css";
import trailer from "../assets/assemble-trailer.mp4"

class SectionVideo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      videoPlaying: false
    };

    this.video = React.createRef();

    this.handleClick = this.handleClick.bind(this);
    this.handlePause = this.handlePause.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.setState({ videoPlaying: true });
    this.video.current.play();
  }

  handlePause(e) {
    e.preventDefault();
    this.setState({ videoPlaying: false });
    this.video.current.pause();
  }

  render() {
    const { data, height, documentScrollPosition, width, shouldHideMenu } = this.props;
    const { videoPlaying } = this.state;

    const overlayClasses = classnames(cssClass.overlay, {
      [cssClass.hide]: videoPlaying
    });

    const videoClasses = classnames(cssClass.mainVideo, {
      [cssClass.popOut]: (documentScrollPosition > height * 2) && videoPlaying,
      [cssClass.coverScreen]: width > height
    });

    if (videoPlaying && (documentScrollPosition < height * 2) && documentScrollPosition > height) {
      shouldHideMenu(true);
    } else {
      shouldHideMenu(false);
    }

    return (
      <div className={cssClass.video}>
        <video
          src={trailer}
          onClick={this.handlePause}
          playsInline
          controls
          autoPlay={videoPlaying}
          className={videoClasses}
          ref={this.video}
          preload={width > 1000 ? "auto" : "none"}
          type="video/mp4"
        >
          <track kind="captions" />
        </video>
        <div 
          className={overlayClasses}
          onClick={this.handleClick}
          onKeyPress={this.handleClick}
          role="button"
          tabIndex="0"
        >
          <Img fluid={data.file.childImageSharp.fluid} alt="" className={cssClass.bgImage} />
          <div className={cssClass.play}>
            <div className={cssClass.playIcon} />
            Play Video
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  shouldHideMenu: bool => dispatch({ type: "MENUTOGGLE", bool })
});

const ConnectedComponent = connect(
  null,
  mapDispatchToProps
)(SectionVideo);

export default props => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "video-placeholder.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => <ConnectedComponent data={data} {...props} />}
  />
);