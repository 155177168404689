import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import cssClass from "./header.module.css";
import UstwoLogo from "../assets/ustwo-logo";

const Header = ({ data }) => {
  const steamButton = data.steam.childImageSharp.fluid;
  const appleButton = data.apple.childImageSharp.fluid;
  const googleButton = data.google.childImageSharp.fluid;

  return (
    <header className={cssClass.header}>
      <div className={cssClass.logo}>
        <a href="https://ustwogames.co.uk"><UstwoLogo /></a>
      </div>
      <div className={cssClass.buttonLinks}>
        <div className={cssClass.otherLink}>
          <a href="https://apps.apple.com/app/id6459t060454"><Img fluid={appleButton} /></a>
        </div>
        <div className={cssClass.otherLink}>
          <a href="https://play.google.com/store/apps/details?id=com.ustwo.awc"><Img fluid={googleButton} /></a>
        </div>
        <div className={cssClass.otherLink}>
          <a href="https://store.steampowered.com/app/1202900/Assemble_with_Care/"><Img fluid={steamButton} /></a>
        </div>
      </div>
    </header>
  );
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        steam: file(relativePath: { eq: "logo-steam.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        apple: file(relativePath: { eq: "logo-appStore.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        google: file(relativePath: { eq: "logo-googlePlay.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
);