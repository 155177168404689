import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import cssClass from "./section-about.module.css";

const SectionAbout = ({ data, height, documentScrollPosition }) => {
  const translate = ((documentScrollPosition - (height * 2)) / height) * 100;
  const styles = {
    transform: `translateY(-${translate + 5}px)`
  }

  return (
    <div className={cssClass.about}>
      <div className={cssClass.text}>
        <p>
          From the studio that brought you Monument Valley, comes a story about taking things apart and putting ourselves back together.
        </p>
        <p>
          When Maria, a globe-trotting antique restorer, arrives in the sun-soaked town of Bellariva, she has no idea just how broken it will turn out to be. She wants nothing more than to help the town’s inhabitants save their most beloved possessions,
  but when it’s their personal lives that are starting to fracture, she’ll need to find a way to hold them together, one spare screw at a time.
        </p>
      </div>
      <div className={cssClass.imageWrapper} style={styles}>
        <Img fluid={data.file.childImageSharp.fluid} alt="" className={cssClass.image} />
      </div>
    </div>
  );
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "cassette.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => <SectionAbout data={data} {...props} />}
  />
);