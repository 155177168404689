import React, { Component } from "react";
import classnames from "classnames";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import Header from "../components/header";
import Menu from "../components/menu";
import PressKit from "../components/press-kit";
import Footer from "../components/footer";
import SEO from "../components/seo";
import "../css/reset.css";
import "../css/base.css";
import cssClass from "./index.module.css";
import sections from "../content/sections";

class IndexPage extends Component {
  constructor(props) {
    super(props);

    this.getViewportDimensions = this.getViewportDimensions.bind(this);
    this.getDocumentScrollPosition = this.getDocumentScrollPosition.bind(this);

    this.state = {
      height: 0,
      width: 0,
      documentScrollPosition: 0
    };
  }

  componentDidMount() {
    this.getViewportDimensions();
    window.addEventListener("resize", this.getViewportDimensions, false);
    window.addEventListener("scroll", this.getDocumentScrollPosition, {
      passive: true
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.getViewportDimensions, false);
    window.removeEventListener("scroll", this.getDocumentScrollPosition, {
      passive: true
    });
  }

  getViewportDimensions() {
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth
    });
  }

  getDocumentScrollPosition() {
    this.setState({
      documentScrollPosition: document.scrollingElement.scrollTop
    });
  }

  render() {
    const { hideMenu } = this.props;
    const { height, width, documentScrollPosition } = this.state;
    
    const sectionChangeForMenu = Math.ceil((documentScrollPosition + (height * 0.25)) / height);

    const menuActiveSectionNumber = isFinite(sectionChangeForMenu) ? sectionChangeForMenu : 1; // Fix infinity on load
    const fixNumber = menuActiveSectionNumber < 1 ? 1 : menuActiveSectionNumber;
    const menuActiveSection = menuActiveSectionNumber > sections.length ? sections[sections.length - 1].name : sections[fixNumber - 1].name;

    const renderSections = sections.map((section, i) => {
      const Component = section.component;
      const classes = classnames(cssClass.section, cssClass[section.name]);

      return (
        <section className={classes} key={section.name} id={section.name}>
          <Component 
            width={width} 
            documentScrollPosition={documentScrollPosition} 
            height={height} 
          />
        </section>
      );
    });
    
    return (
      <>
        <Helmet>
          <script type="text/javascript">
            {`
                WebFontConfig = {
                  google: {families: [ 'Josefin+Sans|Noto+Serif&display=swap' ] }
                };
                (function() {
                  var wf = document.createElement('script');
                  wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
                  wf.type = 'text/javascript';
                  wf.async = 'true';
                  var s = document.getElementsByTagName('script')[0];
                  s.parentNode.insertBefore(wf, s);
                })(); 
              `}
          </script>
          <meta property="og:image" content="https://assemblegame.com/assemble-with-care-splash.jpg" />
        </Helmet>
        <SEO title="Assemble with Care | ustwo Games" />
        <Header />
        <Menu activeSection={menuActiveSection} hideMenu={hideMenu} />
        {renderSections}
        <PressKit />
        <Footer />
      </>
    );
  }
}

const mapStateToProps = ({ hideMenu }) => ({ hideMenu });

export default connect(mapStateToProps)(IndexPage);