import React from "react";
import cssClass from "./footer.module.css";
import UstwoLogo from "../assets/ustwo-logo";
import IconInstagram from "../assets/icon-instagram.png";
import IconTwitter from "../assets/icon-twitter.png";

const Footer = () => (
  <footer className={cssClass.footer}>
    <div className={cssClass.footerInner}>
      <div className={cssClass.logo}>
        <a href="https://ustwogames.co.uk" title="ustwo Games"><UstwoLogo /></a>
      </div>
      <div className={cssClass.smallPrint}>
        <p>Proud to be part of <br /><a href="https://ustwo.com">ustwo</a>.</p>
      </div>
      <div className={cssClass.social}>
        <a href="https://www.instagram.com/ustwogames/"><img src={IconInstagram} alt="Instagram" className={cssClass.instagram} /></a>
        <a href="https://twitter.com/ustwogames"><img src={IconTwitter} alt="Twitter" className={cssClass.twitter} /></a>
      </div>
    </div>
  </footer>
);

export default Footer
