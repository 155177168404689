import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import cssClass from "./section-features.module.css";

const SectionFeatures = ({ data }) => (
  <div className={cssClass.features}>
    <div className={cssClass.text}>
      <div className={cssClass.item}>
        <h2>Meaningful Puzzles</h2>
        <p>Explore the objects through Maria’s eyes and find a way to restore them.</p>
      </div>
      <div className={cssClass.item}>
        <h2>Evocative Story</h2>
        <p>Meet the townsfolk of Bellariva. Come to love their quirks and help them reconnect.</p>
      </div>
      <div className={cssClass.item}>
        <h2>Nostalgic Charm</h2>
        <p>Rediscover the defining objects of decades past to the tune of the original soundtrack, inspired by the sounds of the 80s.</p>
      </div>
      <div className={cssClass.item}>
        <h2>Handcrafted Visuals</h2>
        <p>Enjoy gameplay rendered in a beautiful, impressionist style, alongside a completely hand-illustrated story.</p>
      </div>
    </div>
    <div className={cssClass.imageWrapper}>
      <Img fluid={data.file.childImageSharp.fluid} alt="" className={cssClass.image} />
    </div>
  </div>
);

export default props => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "alessandra.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => <SectionFeatures data={data} {...props} />}
  />
);